$clr-primary-1: #3d405b;
$clr-primary-11: rgba(61, 64, 91, 0.752);
$clr-primary-2: #595d83;
$clr-primary-3: #1b1c1a;
$clr-primary-4: #51557a;
$clr-primary-5: teal;
$clr-shadow-2: rgba(167, 167, 40, 0.25);
$clr-shadow-1: rgba(0, 0, 0, 0.2);
$clr-text-1: rgb(80, 80, 80);
$clr-text-2: grey;
$clr-text-3: #0078d4;
$clr-text-4: #1e6673;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Sedan:ital@0;1&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chewy&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap");
$font-playfair-display: "Playfair Display", serif;
$font-rubik: "Rubik", sans-serif;
$font-ubuntu: "Ubuntu", sans-serif;
$font-raleway: "Raleway", sans-serif;
$font-sedan: "Sedan", serif;
$font-roboto: "Roboto", sans-serif;
$font-montserrat: "Montserrat", sans-serif;
$font-jetbrains-mono: "JetBrains Mono", monospace;
