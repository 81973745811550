html,
body {
  // background: rgb(247, 247, 247);
}

html,
body,
accordion-header {
  font-family: $font-roboto;
  font-family: $font-raleway;
  font-family: $font-playfair-display;
  font-family: $font-sedan;
  // font-family: $font-rubik;
  font-family: $font-ubuntu;
  // font-family: $font-montserrat;
}

h1,
h2,
h3,
h4,
h5,
h6,
.text-color-1,
.nav-link {
  color: $clr-primary-1;
  font-family: $font-sedan;
}

.footer-heading {
  font-weight: bold;
}

.form-control:focus {
  ///* border-color: none; */
  ///* border:3px solid red; */
  ///* box-shadow: 0 0 0 0.2rem rgb(0, 229, 255) !important; */
}

.container {
  max-width: 1080px;
}

.image-container {
  display: flex;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  max-height: 500px;
}

.underline:hover {
  color: $clr-primary-1;
}

.design a {
  text-decoration: none;
}

.reservation {
  background-color: black;
  color: greenyellow;
  border: 2px solid black;
  font-family: $font-ubuntu;
}

.reservation.active {
  // color: red;
}

.reservation:active {
  // border: 2px solid white;
}

.language-switcher {
  background-color: none;
  color: $clr-primary-1;
  margin-left: 15px !important;
  // border: 2px solid $clr-primary-1;
  font-family: $font-jetbrains-mono;
}

.language-switcher-btn {
  border: 2px solid $clr-primary-1;
}

.language-switcher:active {
  // border: 2px solid white;
}

.ls-btn {
  background-color: rgb(223, 228, 231);

  border: none;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 14px;
  font-family: $font-jetbrains-mono;
}

.ls-btn-selected {
  background-color: rgb(175, 204, 220);
}

.ls-btn-1 {
  border-right: 2px solid black;
}
.ls-btn-3 {
  border-left: 2px solid black;
}

#navbarNav {
  // border-right: 1px solid red;
  // padding-right: 10px;
}

.stacked-button {
  width: 120px;
  margin: 2px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  background-color: rgba(144, 185, 183, 0.485);
  color: black;
  border: 2px solid black;
}

.stacked-button:hover {
  background-color: $clr-primary-1;
  box-shadow: 0 0 10px $clr-shadow-2 inset;
  color: white;
}

.stacked-button:active {
  box-shadow: 0 0 10px $clr-shadow-1 inset;
  border-color: white;
}

.reservation:hover {
  color: white;
}

.navbar-toggler {
  border: none;
  color: antiquewhite;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none !important;
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(61, 64, 91, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.faq {
  font-weight: bold;
  font-size: 18px;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.bd-mode-toggle {
  z-index: 1500;
}

a {
  text-decoration: none;
  color: $clr-text-4;
}

input[type="checkbox"]:checked {
  background-color: green !important;
}
input[type="checkbox"]:focus {
  outline: 0;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-color: grey;
}

footer .col {
  white-space: nowrap;
  flex-wrap: nowrap;
}

.navbar-nav .nav-link.active {
  // font-weight: 900;
  color: green;
  // color: red;
  // background-color: rgba(0, 195, 255, 0.239);
}

.nav-link:active {
  color: white;
}

.navbar {
  border-bottom: 2px solid $clr-primary-11;
}

footer {
  border-top: 2px solid $clr-primary-11;
}

.accordion {
  --bs-accordion-btn-color: black;
  --bs-accordion-btn-bg: white;
  --bs-accordion-active-color: $clr-primary-1;
  --bs-accordion-active-bg: rgba(3, 62, 67, 0.191);
  --bs-accordion-active-bg: none;
  border: 0px;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: none;
}

.accordion-body {
  padding-top: 0px;
}

.accordion-item,
.accordion-header {
  border: 0px;
  border-top: 0.5px solid $clr-text-1;
}

.accordion-item {
  border: 0px;
}

#faqAccordion {
  border-bottom: 0.5px solid $clr-text-1;
}

.accordion-body {
  border: 0px;
}

a:hover {
  color: $clr-text-3;
}

.accordion-button {
  font-weight: 500;
  color: $clr-text-1;
  ///* border-bottom: 2px solid; */
}

.about-text {
  color: $clr-text-1;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  // border-color: #ced4da;
  //  padding-right: 0.75em;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  // border-color: #ced4da;
  // padding-right: 0.75em;
}

.form-control,
.form-check-input {
  outline: none;
  ///* box-shadow:none !important; */
  ///* border:1px solid #ccc !important; */
}

.footerClass {
  position: relative;
  margin-top: -150px;
  height: 150px;
  clear: both;
  padding-top: 20px;
}

img:not([src]) {
  border: 1px solid red;
}

.otpClass {
  background: $clr-shadow-2;
  ///* border: 1px solid grey;   */
}

.otp-field {
  width: 140px;
  font-family: monospace;
}
#phone,
#email {
  font-family: monospace;
}

.btn-custom-1 {
  border: 2px solid $clr-primary-1;

  background: $clr-primary-3;

  color: white;
}

.btn-custom-1:hover {
  border: 2px solid $clr-primary-1;
  background: $clr-primary-1;
  box-shadow: 0 0 10px $clr-shadow-1 inset;
  color: white;
}

.btn-custom-1:disabled {
  border: 2px solid $clr-primary-1;
  background: $clr-primary-3;
  color: white;

  opacity: 0.5;
}

.btn-custom-1:active {
  border: 2px solid $clr-primary-1;
  background: $clr-primary-3 !important;
  box-shadow: 0 0 10px $clr-shadow-1 inset;
  color: white !important;
}

.btn-custom-1:focus {
  border: 2px solid rgb(82, 173, 252);
  background: $clr-primary-3 !important;
  color: white !important;
}

.error-message {
  font-size: 15px;
}

.otp-field {
  ///* border: 2px solid black; */
  border: 0px;
}

.timerBtn {
  background: none !important;
  opacity: 1 !important;
  border: none;
  color: red !important;
  font-family: monospace;
  width: 40px;
  margin: 0px;
}

.message-container {
  position: relative;
}

.char-length {
  position: absolute;
  top: 0px;
  right: 0px;
  color: $clr-text-2;
  font-size: 16px;
  margin: 0px;
  margin-right: 12px;
  margin-top: 8px;
  text-align: right;
  ///* background: rgba(255, 255, 255, 0.871); */
}

.high-length {
  color: red;
}

.border-validation2 {
  border-color: black;
  box-shadow: 0 0 4px red !important;
  z-index: 1000;
}

.border-validation1 {
  border-color: black;
  box-shadow: 0 0 6px red !important;
}

.iframe-map iframe {
  height: 100%;
  width: 100%;
}

.maps-class1 {
  width: 100%;
  height: 100%;
  min-height: 300px;

  border: 1px solid $clr-primary-1;
}

.maps-class {
  width: 100%;
  min-height: 300px;
  max-height: 400px;
}

.toast-container {
  ///* background-color: red; */
  background-color: none;
}
.toast {
  background-color: none;

  width: 100%;
}

.toast-header {
  background-color: rgb(233, 255, 235);
  border: 2px solid rgb(0, 132, 29);
  color: black;
  font-size: 16px;
  font-weight: 300;
}

.submission-error-message {
  color: red;
}

@keyframes tick {
  from {
    stroke-dashoffset: 350;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes tick-scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.tick-icon {
  margin-right: 5px;
  font-size: 16px;
}

.tick-path {
  transform-origin: 50% 50%;
  transform: scale(0);
  animation: tick-scale 2s ease-in-out forwards;
}

.tick {
  stroke-dasharray: 350;
  stroke-dashoffset: 350;
}
svg .tick {
  animation: tick 0.8s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.submitted {
  background-color: #000000 !important;
  ///* color: #f5aeae;  */
  animation: fadeOutBtn 2s ease forwards;

  svg path {
    fill: rgb(111, 255, 0);
  }
}
.submitting-animation {
  animation: button-animation 1s infinite;
}

@keyframes fadeOutBtn {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes button-animation {
  0% {
    background-color: #000000;
  }
  50% {
    background-color: #2a2a2a;
  }
  100% {
    background-color: #000000;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ls-modal-fadeout {
  animation: 0.3s ease-out 0s 1 fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.toast-container {
  animation: 0.3s ease-out 0s 1 FadeIn;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 9998;
  animation: 0.3s ease-out 0s 1 FadeIn;
}

.ls-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: none;

  width: 60vw;
  max-width: 200px;
  height: 160px;
  padding: 20px;
  border-radius: 0px;

  border: 1px solid rgb(0, 0, 0);

  backdrop-filter: blur(10px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: 0.3s ease-out 0s 1 FadeIn;
}

#goToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 9997;
  cursor: pointer;
  padding: 20px;
  color: white;
  border: 1px solid $clr-primary-1;
  border-radius: 100%;
  ///* border-radius: rgb(255, 255, 255) */
  background-color: $clr-primary-3;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-up-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"/> </svg>');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  animation: fadeInMove 0.7s ease forwards;
}

@keyframes fadeInMove {
  0% {
    bottom: 0px;
    right: 20px;
    opacity: 0;
  }
  100% {
    bottom: 20px;
    right: 20px;
    opacity: 1;
  }
}

.otp-verified-confirmation {
  transition: opacity 5s ease-in-out;
  color: green;
}

.footer-docs {
  text-transform: uppercase;
}

.testimonial-prompt {
  background: $clr-shadow-2;
}

.border-nav-l {
  border-right: 1px solid grey;
  // font-size: px;
  color: grey;
}

.border-nav-r {
  border-left: 1px solid grey;
  // font-size: px;
  color: grey;
}

.check-icon {
  // vertical-align: bottom;
}

.about-image {
  height: 300px;
}

.end-quote {
  transform-origin: center;
  transform: rotate(180deg);
}

.alert-warning {
  background-color: rgb(222, 253, 241);
  border: 0px solid black;
}

.home-intro {
  font-family: $font-roboto;
  font-weight: 400;
}

.cookie-consent-banner {
  z-index: 100000000;
}
.cookie-button {
  width: 120px;
  margin: 2px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  background-color: rgba(144, 185, 183, 0.485);
  color: black;
  border: 2px solid black;
  border-radius: 0px;
}

.cookie-button :hover {
  background-color: $clr-primary-1 !important;
  box-shadow: 0 0 10px $clr-shadow-2 inset;
  color: white;
}

.cookie-button :active {
  box-shadow: 0 0 10px $clr-shadow-1 inset;
  border-color: white;
}

.video {
  width: 100%;
  height: auto;
}

.custom-col {
  // max-width: 300px;
}

.v1 {
  position: fixed;
  z-index: -99;
  width: 100%;
  height: 100%;
}

// .ytvid {
//   width: 100%;
//   height: 500px;
//   // max-height: 500px;
// }

.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Disable pointer events */
}

.video-container1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-container1 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .video-container {
    padding-top: 56.5%; /* Adjust the padding to maintain the aspect ratio for smaller screens */
  }
}

@media (hover: none) and (pointer: coarse) {
  .video-container iframe {
    pointer-events: auto; /* Enable pointer events for touch devices */
  }
}

// .video-container {
//   position: relative;
//   width: 100%;
//   overflow: hidden;
// }

// .video-container::before {
//   content: "";
//   display: block;
//   padding-top: 56.25%; /* 16:9 Aspect Ratio */
// }

// .video-container iframe {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   pointer-events: none; /* Disable pointer events */
// }

// @media (max-width: 768px) {
//   .video-container {
//     padding-top: 56.25%; /* Maintain aspect ratio for smaller screens */
//   }
// }

// @media (hover: none) and (pointer: coarse) {
//   .video-container iframe {
//     pointer-events: auto; /* Enable pointer events for touch devices */
//   }
// }

.vrp {
  color: black;
}

.vrp1 {
  font-weight: bold;
}

.booking-hl {
  color: rgb(223, 122, 0);
  padding-bottom: 2px;
  border-bottom: 2px solid red;
}

.bg-warning {
  background: rgb(255, 240, 191) !important;
}

.popup {
  background-color: rgb(255, 255, 255);
}

.testimonial-card {
  // background: rgb(255, 253, 222);
  border: 2px solid rgb(159, 99, 2);
}

.testimonial-image {
  // border: 2px solid rgb(255, 221, 0);
  background: #fffcf7;
}

.language-switcher {
  z-index: 9999;
}

.popup-close-btn {
  background-color: $clr-primary-1;
}
